import React from 'react';
import PropTypes from 'prop-types';
import styled, { keyframes } from 'styled-components';
import ShortPartTile from 'components/molecules/ShortPartTile/ShortPartTile';
import ThemeShape8 from 'images/themeShapes/themeShape_8.svg';
import { H2, H3 } from 'components/atoms/Typography';
import { colors, breakPoints } from 'utils/variables';
import { LinkButton } from 'components/atoms/Button/Button';
import { useStaticQuery, graphql } from 'gatsby';

const StyledWrapper = styled.div`
  position: relative;
  margin: 10rem 0 1.1rem 0;

  @media (min-width: ${breakPoints.tablet}) {
    margin: 23.1rem 0 1.1rem 0;
  }

  > button {
    position: relative;
    display: block;
    z-index: 10;
    margin: 6.2rem auto 0 auto;
    animation: ${keyframes`
      0%, 5% {
        transform: rotateZ(0);
      }
      1%, 3% {
        transform: rotateZ(-5deg);
      }
      2%, 4% {
        transform: rotateZ(5deg);
      }
    `} 10s linear infinite;
  }
`;

const StyledHeading = styled.div`
  position: relative;
  z-index: 10;
  text-align: center;

  > h3 {
    margin-bottom: 1.7rem;
  }
  > h2 {
    margin-bottom: 4.7rem;
  }
`;

const StyledContent = styled.div`
  position: relative;
  z-index: 10;
  display: flex;
  justify-content: space-around;
`;

const StyledShape = styled.img`
  position: absolute;
  top: -60rem;
  right: -73rem;
`;

const getTiles = (part, index) => <ShortPartTile part={part.frontmatter} key={`${part.frontmatter.title}-${index}`} />;

const ShortShop = ({ withThemeShapes }) => {
  const {
    allMarkdownRemark: { parts },
  } = useStaticQuery(graphql`
    query {
      allMarkdownRemark(sort: { order: DESC, fields: frontmatter___title }, limit: 4) {
        parts: nodes {
          frontmatter {
            image {
              childImageSharp {
                fluid(quality: 100, maxWidth: 400) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
            title
            description {
              name
              value
            }
          }
        }
      }
    }
  `);

  return (
    <StyledWrapper>
      <StyledHeading>
        <H3 color={colors.color3}>Prowadzimy sprzedaż części samochodowych</H3>
        <H2>
          Sprawdź aktualne oferty naszych
          <br />
          <b>części samochodowych</b>
        </H2>
      </StyledHeading>
      <StyledContent>{parts.map(getTiles)}</StyledContent>
      <LinkButton to="/sprzedaz-czesci" text="Zobacz wszystkie produkty" />
      {withThemeShapes && <StyledShape src={ThemeShape8} alt="Short services background" />}
    </StyledWrapper>
  );
};

ShortShop.propTypes = {
  withThemeShapes: PropTypes.bool,
};

ShortShop.defaultProps = {
  withThemeShapes: true,
};

export default ShortShop;

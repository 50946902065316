import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Img from 'gatsby-image';
import styled from 'styled-components';
import { H5 } from 'components/atoms/Typography';
import { ShapeLinkButton } from 'components/atoms/Button/Button';
import { colors, breakPoints } from 'utils/variables';
import emptyImage from 'images/emptyImage.jpg';
import { hexToRGBA } from 'utils/lib';
import PartModalContent from 'components/molecules/PartModalContent/PartModalContent';
import Modal from 'react-modal';

const StyledWrapper = styled.div`
  position: relative;
  text-align: center;
  width: 19rem;
  height: 40.5rem;
  transition: transform 300ms ease;

  :not(:first-child) {
    display: none;
  }

  @media (min-width: ${breakPoints.tablet}) {
    &:nth-child(1):after,
    &:nth-child(2):after {
      content: '';
      height: inherit;
      width: 0.1rem;
      background-color: #ededed;
      position: absolute;
      top: 0;
      right: -2.5rem;
    }

    :nth-child(2),
    :nth-child(3) {
      display: block;
    }
  }

  @media (min-width: ${breakPoints.desktop}) {
    &:nth-child(1):after,
    &:nth-child(2):after,
    &:nth-child(3):after {
      content: '';
      height: inherit;
      width: 0.1rem;
      background-color: #ededed;
      position: absolute;
      top: 0;
      right: -5.6rem;
    }

    :nth-child(4) {
      display: block;
    }
  }

  > h5 {
    margin: 1.2rem 1rem 1rem 1rem;
    min-height: 4rem;
  }

  > button {
    border: 0;
    cursor: pointer;
    padding: 0 1.3rem 0 0;
    margin-top: 0.9rem;

    svg {
      margin-left: 2.5rem;
    }
  }

  &:hover {
    transform: translateY(-2rem);

    button {
      padding-bottom: 2rem;
    }
  }
`;

const StyledImageContainer = styled.div`
  position: relative;
  width: 19rem;
  height: 19rem;

  > div {
    box-shadow: 0 0.3rem 0.6rem ${hexToRGBA('#000000', 0.16)};
    border-radius: 0.3rem;
  }
`;

const StyledDescription = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  max-height: 11.5rem;
  overflow-y: hidden;

  &:after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background-color: transparent;
    background-image: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100%);
  }

  > span {
    font-size: 1.4rem;
    font-weight: 500;
    line-height: 2.3rem;
    color: ${colors.color2};
  }
`;

const getDescription = (description, index) => (
  <span key={`${description.name}-${index}`}>
    <b>{description.name}:</b> {description.value}
  </span>
);

const ShortPartTile = ({ part }) => {
  const { image, title, description } = part;

  const [modalIsOpen, setIsOpen] = useState(false);
  const openModal = () => setIsOpen(true);
  const closeModal = () => setIsOpen(false);

  return (
    <StyledWrapper>
      <StyledImageContainer>
        {image.childImageSharp ? (
          <Img fluid={image.childImageSharp.fluid} alt={title} style={{ width: '19rem', height: '19rem' }} />
        ) : (
          <img src={emptyImage} alt="empty" style={{ width: '19rem', height: '19rem' }} />
        )}
      </StyledImageContainer>
      <H5>{title}</H5>
      <StyledDescription className="desc">{description.map(getDescription)}</StyledDescription>
      <ShapeLinkButton onClick={openModal} text="Poznaj szczegóły" />
      <Modal isOpen={modalIsOpen} onRequestClose={closeModal} className="Modal-content" overlayClassName="Modal-overlay">
        <PartModalContent part={part} closeModal={closeModal} />
      </Modal>
    </StyledWrapper>
  );
};

ShortPartTile.propTypes = {
  part: PropTypes.shape({
    image: PropTypes.shape({
      childImageSharp: PropTypes.shape({
        fluid: PropTypes.object,
      }),
    }),
    title: PropTypes.string,
    description: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string,
        value: PropTypes.string,
      }),
    ),
  }).isRequired,
};

export default ShortPartTile;

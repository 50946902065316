import React from 'react';
import styled from 'styled-components';
import Text, { H2, H3 } from 'components/atoms/Typography';
import { graphql, useStaticQuery } from 'gatsby';
import Img from 'gatsby-image';
import { colors, breakPoints } from 'utils/variables';
import { hexToRGBA } from 'utils/lib';

const StyledWrapper = styled.div`
  position: relative;
  z-index: 10;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin: 0 0 8.9rem 0;

  @media (min-width: ${breakPoints.desktop}) {
    justify-content: space-between;
  }
`;

const StyledImages = styled.div`
  display: flex;
  width: 100%;

  @media (min-width: ${breakPoints.desktop}) {
    width: auto;
  }

  > div {
    border-radius: 0.3rem;
  }

  .back {
    width: 28rem;
    height: 23rem;
    top: 16rem;
    left: calc(50% - 15rem);

    @media (min-width: ${breakPoints.tablet}) {
      width: 34rem;
      height: 28rem;
      top: 16rem;
      left: calc(50% - 30rem);
    }

    @media (min-width: ${breakPoints.desktop}) {
      width: 47.4rem;
      height: 39.3rem;
      top: -9.4rem;
      left: 0.4rem;
    }
  }

  .front {
    width: 23.2rem;
    height: 16rem;
    top: 32rem;
    right: calc(50% - 16rem);
    box-shadow: -0.3rem -0.3rem 0.6rem ${hexToRGBA('#000000', 0.25)};

    @media (min-width: ${breakPoints.tablet}) {
      width: 33rem;
      height: 24rem;
      top: 25rem;
      right: calc(50% - 30rem);
    }

    @media (min-width: ${breakPoints.desktop}) {
      width: 32.2rem;
      height: 22.4rem;
      top: 13.5rem;
      left: 23rem;
      right: unset;
    }
  }
`;

const StyledContent = styled.div`
  text-align: center;

  @media (min-width: ${breakPoints.desktop}) {
    text-align: right;
  }

  h3 {
    margin-bottom: 1.7rem;
  }
  h2 {
    max-height: 8rem;
    margin-bottom: 40rem;

    @media (min-width: ${breakPoints.desktop}) {
      margin-bottom: 5.6rem;
    }
  }
  .text {
    margin-bottom: 3.3rem;

    @media (min-width: ${breakPoints.desktop}) {
      width: 60rem;
    }
  }
`;

const OurPriorities = () => {
  const { imageBack, imageFront } = useStaticQuery(graphql`
    query {
      imageBack: file(relativePath: { eq: "ourPriorities-Back.jpg" }) {
        childImageSharp {
          fluid(quality: 100, maxHeight: 500) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      imageFront: file(relativePath: { eq: "ourPriorities-Front.jpg" }) {
        childImageSharp {
          fluid(quality: 100, maxHeight: 350) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `);

  return (
    <StyledWrapper>
      <StyledImages>
        <Img alt="Nasze priorytety - back" className="back" fluid={imageBack.childImageSharp.fluid} style={{ position: 'absolute' }} />
        <Img alt="Nasze priorytety - front" className="front" fluid={imageFront.childImageSharp.fluid} style={{ position: 'absolute' }} />
      </StyledImages>
      <StyledContent>
        <H3 color={colors.color3}>Jakie mamy wartości?</H3>
        <H2
          dangerouslySetInnerHTML={{
            __html: 'Naszym priorytetem jest<br/><b>świetna jakość</b>',
          }}
        />
        <Text html="Uczestniczymy w licznych szkoleniach w celu podwyższania naszych kwalifikacji." />
      </StyledContent>
    </StyledWrapper>
  );
};

export default OurPriorities;
